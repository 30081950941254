<template>
    <div id="features">
        <h2>The difference is clear.</h2>

        <div class="row">
            <div class="col-lg-6">
                <img src="@/assets/img/video.png" style="width: 100%; height: auto;">
            </div>
            <div class="col-lg-5 offset-lg-1">
                <div class="row">
                    <div class="col-6">
                        <indiv-stats
                            title="Inventory Management"
                            description="Effortlessly track your stock levels, orders, and deliveries in real-time. Our smart inventory management system adapts as your business grows, ensuring you never miss a beat."
                        ></indiv-stats>
                    </div>
                    <div class="col-6">
                        <indiv-stats
                            title="Point-of-Sale Solutions"
                            description="Simplify your transactions with an intuitive POS interface. Compatible with multiple payment options, our POS ensures quick, secure, and hassle-free transactions."
                        ></indiv-stats>
                    </div>
                    <div class="col-6">
                        <indiv-stats
                            title="AI-Driven Dashboard Analytics"
                            description="Harness the power of data with our AI-driven dashboard. Real-time insights help you understand customer behavior, track sales performance, and optimize your business strategies."
                        ></indiv-stats>
                    </div>
                    <div class="col-6">
                        <indiv-stats
                            title="Pay-As-You-Grow Model"
                            description="Begin your journey with our basic plan and scale seamlessly as your business grows. With our Pay-As-You-Grow model, you only pay for the features you need, when you need them."
                        ></indiv-stats>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import indivStats from './Features/indivStats.vue';

    export default {
        data () {
            return {

            }
        },
        components : {
            indivStats
        },
        mounted () {

        },
        methods : {

        }
    }
</script>

<style scoped>
    #features {
        padding: 100px 0;
    }

    #features h2 {
        font-size: 4em;
        font-weight: bold;
        margin-bottom: 50px;
    }
</style>