<template>
    <div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-12" style="padding: 40px" v-for="(stats, key) in statistics" :key="key">
                
                <div class="rounded shadow py-5 px-5 bg-white h-100">
                    <p style="font-size: 18px; font-weight: 600;">{{ stats.title }}</p>
                    <h2 style="font-size: 28px; font-weight: 800;">{{ stats.value }}</h2>
                </div>

            </div>
        </div>

        <div class="row">
            <div class="col-lg-3">
                <div v-for="(stats, key) in statistics2" :key="key" style="padding: 30px;">
                    <div class="rounded shadow py-5 px-5 bg-white">
                        <p style="font-size: 18px; font-weight: 600;">{{ stats.title }}</p>
                        <h2 style="font-size: 28px; font-weight: 800;">{{ stats.value }}</h2>
                    </div>
                </div>
            </div>
            <div class="col-lg-9" style="padding: 30px 40px;">

                <div class="rounded shadow py-5 px-5 bg-white h-100">
                    <h2>Predictive Sales Trend Analysis</h2>
                    <predictiveSalesTrendAnalysis></predictiveSalesTrendAnalysis>
                </div>

            </div>
        </div>

        <div class="row">
            <div class="col-lg-8" style="padding: 30px 40px;">
                <div class="rounded shadow py-5 px-5 bg-white h-100">
                    <h2>Product Recommendations Engine</h2>
                    <productRecommendationsEngine></productRecommendationsEngine>
                </div>
            </div>
            <div class="col-lg-4" style="padding: 30px 40px;">
                <div class="rounded shadow py-5 px-5 bg-white h-100">
                    <h2>Category Preferences</h2>
                    <categoryPreferences></categoryPreferences>
                </div>
            </div>
        </div>

        <div class="rounded shadow p-5 bg-white" style="margin: 30px">
            <h2>Anomaly Detection In Expenses</h2>
            <anomalyDetectionInExpenses></anomalyDetectionInExpenses>
        </div>

        <div style="display: flex; align-items: center; justify-content: center; padding: 50px 0">
            <button class="btn btn-info" type="button" @click="$router.push('/')" style="margin-right: 50px;">Go To Home</button>
            <button class="btn btn-primary" type="button" @click="$router.push('/pos/counter-checkout')">Go To Point Of Sales</button>
        </div>

    </div>
</template>

<script>
    import AppOptions from '../config/AppOptions.vue'

    import predictiveSalesTrendAnalysis from './Dashboard/predictiveSalesTrendAnalysis'
    import anomalyDetectionInExpenses from './Dashboard/anomalyDetectionInExpenses'
    import productRecommendationsEngine from './Dashboard/productRecommendationsEngine'
    import categoryPreferences from './Dashboard/categoryPreferences'

    export default {
        data () {
            return {
                statistics : [
                    {title:'Real-Time Revenue', value:'Rp. 18,185,050'},
                    {title:'Average Transaction Value', value:'Rp. 82,231'},
                    {title:'Customer Retention Rate', value:'72%'},
                    {title:'Gross Profit Margin', value:'46%'}
                ],
                statistics2 : [
                    {title:'Customer Acquisition Cost', value:'Rp. 38,238'},
                    {title:'Inventory Turnover Ratio', value:'6.5'},
                    {title:'Days to Restock', value:'7 Days'}
                ]
            }
        },
        components : {
            predictiveSalesTrendAnalysis,
            anomalyDetectionInExpenses,
            productRecommendationsEngine,
            categoryPreferences,
        },
        created() {
            AppOptions.appEmpty = true;
            this.handleStartTime();
        },
        beforeRouteLeave(to, from, next) {
            AppOptions.appEmpty = false;
            next();
        },
        mounted () {

        },
        methods : {

        }
    }
</script>

<style scoped>

</style>