<template>
    <div class="indivStats">
        <h5>{{ title }}</h5>
        <p>{{ description }}</p>
    </div>
</template>

<script>
export default {
    props : ['title', 'description']
}
</script>

<style scoped>
    .indivStats {
        margin-top: 20px;
    }

    h5 {
        font-size: 1.5em;
        font-weight: bold;
        color: #4338ca;
    }

    p {
        font-size: 1.2em;
        font-weight: 300;
        color: #3f3f3f;
        text-align: justify;
    }
</style>