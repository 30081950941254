<template>
    <div id="faq">

        <div class="row">
            <div class="col-lg-5" style="display: flex; flex-direction: column; justify-content: space-between;">
                <div>
                    <h2>Frequently Asked Question</h2>
                    <p class="description">Navigating the complexities of business management software can be daunting. We've compiled some of the most commonly asked questions about PIA to help you get the clarity you need.</p>
                </div>

                <div>
                    <div
                        class="faqModule"
                        v-for="(item, index) in data"
                        :key="index"
                    >
                        <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: flex-start;">
                            <h6>{{ item.question }}</h6>
                            <div style="display: flex; flex-direction: row; align-items: center;">
                                <div class="minus" v-if="index == opened" @click="opened = -1">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-1-4">
                                        <circle cx="9" cy="9" r="9" fill="#2563EB"></circle>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 9.00002C6 8.66865 6.26863 8.40002 6.6 8.40002L11.4 8.40002C11.7314 8.40002 12 8.66865 12 9.00002C12 9.3314 11.7314 9.60002 11.4 9.60002L6.6 9.60002C6.26863 9.60002 6 9.3314 6 9.00002Z" fill="white"></path>
                                    </svg>
                                </div>
                                <div class="plus" v-if="index != opened" @click="opened = index">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-2-4">
                                        <circle cx="9" cy="9" r="9" fill="#2563EB"></circle>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.00078 4.79999C9.33215 4.79999 9.60078 5.06862 9.60078 5.39999V8.39999H12.6008C12.9322 8.39999 13.2008 8.66862 13.2008 8.99999C13.2008 9.33136 12.9322 9.59999 12.6008 9.59999H9.60078V12.6C9.60078 12.9314 9.33215 13.2 9.00078 13.2C8.66941 13.2 8.40078 12.9314 8.40078 12.6V9.59999H5.40078C5.06941 9.59999 4.80078 9.33136 4.80078 8.99999C4.80078 8.66862 5.06941 8.39999 5.40078 8.39999L8.40078 8.39999V5.39999C8.40078 5.06862 8.66941 4.79999 9.00078 4.79999Z" fill="white"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <p class="answer" v-if="index == opened">&nbsp;&nbsp;&nbsp;{{ item.answer }}</p>
                    </div>

                    <p style="margin-top: 40px;">Still have any questions? <span href="#" class="link">Contact Us</span></p>
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1">
                <img src="@/assets/img/faq.png" style="width: 100%; height: auto;">
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        data () {
            return {
                opened : 0,
                data : [
                    {
                        question: "What happens if I go over my subscription limits?",
                        answer: "No need to worry! PIA operates on a flexible Pay-As-You-Grow model. If you exceed your current subscription limits, you'll be notified and given the option to upgrade without any service interruptions. This ensures that as your business grows, PIA can scale right along with you.",
                    },
                    {
                        question: "How does PIA help solve operational challenges?",
                        answer: "PIA is designed to streamline inventory management, point-of-sale processes, and offer insightful AI-driven analytics. Whether you're dealing with supply chain issues or you're trying to make sense of customer behavior, PIA provides actionable insights and automates complex tasks, making problem-solving a breeze.",
                    },
                    {
                        question: "Why are we so confident about the future of PIA?",
                        answer: "Our unique integration of essential business services into one seamless platform provides an unmatched level of convenience and efficiency. Coupled with our Pay-As-You-Grow model, PIA is well-positioned to serve the ever-changing and scaling needs of MSMEs, making us confident about the product's long-term sustainability and growth.",
                    },
                ]
            }
        },
        mounted () {

        },
        methods : {

        }
    }
</script>

<style scoped>
    #faq {
        padding: 100px 0;
    }

    h2 {
        font-size: 4em;
        font-weight: bold;
        margin-bottom: 10px;
    }

    p {
        font-size: 1.2em;
        font-weight: 300;
        margin-bottom: 40px;
        color: #6B7280;
    }

    p.description {
        font-size: 1.2em;
        font-weight: 300;
        margin-bottom: 40px;
        color: #6B7280;
    }

    .link {
        color: #4F46E5;
        font-weight: bold;
    }

    .link:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    #faq .faqModule {
        padding: 24px 0;
        border-bottom: 1px solid #E5E7EB;
    }

    #faq .faqModule h6 {
        font-size: 16px;
        font-weight: bold;
        color: #111827;
    }

    #faq .faqModule p.answer {
        margin-top: 10px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 500;
        color: #6B7280;
        text-align: justify;
    }

    .plus,
    .minus {
        cursor: pointer;
    }
</style>