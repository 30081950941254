<template>
    <div ref="categoryPreferences" style="min-height: 500px;"></div>
</template>

<script>

    /* Imports */
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import am4themes_animated from "@amcharts/amcharts4/themes/animated";
    
    export default {
        data () {
            return [

            ]
        },
        mounted () {
            this.createChart();
        },
        methods : {
            createChart () {
                /* Chart code */
                // Themes begin
                am4core.useTheme(am4themes_animated);
                // Themes end

                let chart = am4core.create(this.$refs.categoryPreferences, am4charts.PieChart3D);
                chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

                chart.data = [
                    {
                        "productCategory": "Main Courses",
                        "percentageValue": 45.0
                    },
                    {
                        "productCategory": "Appetizers",
                        "percentageValue": 25.0
                    },
                    {
                        "productCategory": "Desserts",
                        "percentageValue": 20.0
                    },
                    {
                        "productCategory": "Beverages",
                        "percentageValue": 10.0
                    },
                    {
                        "productCategory": "Specials",
                        "percentageValue": 7.0
                    },
                    {
                        "productCategory": "Sides",
                        "percentageValue": 3.0
                    }
                ];

                chart.innerRadius = am4core.percent(40);
                chart.depth = 120;

                chart.legend = new am4charts.Legend();

                let series = chart.series.push(new am4charts.PieSeries3D());
                series.dataFields.value = "percentageValue";
                series.dataFields.depthValue = "percentageValue";
                series.dataFields.category = "productCategory";
                series.slices.template.cornerRadius = 5;
                series.colors.step = 3;

                // Disabling labels and ticks on inner circle
                series.labels.template.disabled = true;
                series.ticks.template.disabled = true;
            }
        }
    }
</script>