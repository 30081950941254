<template>
    <div id="hero">

        <div class="row">
            <div class="col-lg-6">
                <div style="display: flex; flex-direction: column; justify-content: space-between; height: 100%;">
                    <div>
                        <h1>Unify Your Business, Simplify Your Life</h1>
                        <p class="description">Welcome to PIA—Your all-in-one software solution designed for MSMEs. Seamlessly manage inventory, process sales, and make data-driven decisions with our AI-powered dashboard. Start small, grow big with our unique Pay-As-You-Grow model. Transforming your business has never been this easy.</p>

                        <div style="display: flex; flex-direction: row;">
                            <button class="btn pia-btn"  @click="$router.push('/dashboard')">Join Free for 100 Trx per Month</button>
                            <button class="btn pia-btn-outline" style="margin-left: 30px;">Book A Call</button>
                        </div>
                    </div>
                    
                    <div>
                        <p style="font-size: 1.5em; color: #3f3f3f; font-weight: bold;">TRUSTED AND LOVED BY 10,000+ MSMEs</p>
                        <!-- <div class="d-flex flex-direction-row">
                            <img src="@/assets/img/client1.png" style="margin: 10px 20px 0 0">
                            <img src="@/assets/img/client2.png" style="margin: 10px 20px 0 0">
                            <img src="@/assets/img/client3.png" style="margin: 10px 20px 0 0">
                        </div> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-5 offset-lg-1">
                <img src="@/assets/img/hero.png" style="width: 100%; height: auto;">
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        data () {
            return {

            }
        },
        mounted () {
            
        },
        methods : {

        }
    }
</script>

<style scoped>
    #hero {
        padding: 100px 0;
    }

    #hero h1 {
        font-size: 4.5em;
        font-weight: bold;
        margin-bottom: 50px;
    }

    #hero p.description {
        text-align: justify;
        font-size: 1.4em;
        font-weight: 300;
    }
</style>