<template>
    <div ref="anomalyDetectionInExpenses" style="min-height: 600px;"></div>
</template>

<script>
    /* Imports */
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    export default {
        data () {
            return {

            }
        },
        mounted () {
            this.createChart();
        },
        methods : {
            createChart () {
                /* Chart code */
                // Themes begin
                am4core.useTheme(am4themes_animated);
                // Themes end

                // Create chart instance
                let chart = am4core.create(this.$refs.anomalyDetectionInExpenses, am4charts.XYChart);

                // Add data
                chart.data = [
                    {
                        "ax": 1,
                        "ay": 5000000,
                        "bx": 1,
                        "by": 22000000
                    },
                    {
                        "ax": 2,
                        "ay": 13000000,
                        "bx": 2,
                        "by": 49000000
                    },
                    {
                        "ax": 3,
                        "ay": 23000000,
                        "bx": 3,
                        "by": 51000000
                    },
                    {
                        "ax": 4,
                        "ay": 28000000,
                        "bx": 4,
                        "by": 53000000
                    },
                    {
                        "ax": 5,
                        "ay": 35000000,
                        "bx": 5,
                        "by": 61000000
                    },
                    {
                        "ax": 6,
                        "ay": 51000000,
                        "bx": 6,
                        "by": 83000000
                    },
                    {
                        "ax": 7,
                        "ay": 67000000,
                        "bx": 7,
                        "by": 105000000
                    },
                    {
                        "ax": 8,
                        "ay": 80000000,
                        "bx": 8,
                        "by": 123000000
                    },
                    {
                        "ax": 9,
                        "ay": 89000000,
                        "bx": 9,
                        "by": 145000000
                    },
                    {
                        "ax": 10,
                        "ay": 97000000,
                        "bx": 10,
                        "by": 150000000
                    },
                    {
                        "ax": 11,
                        "ay": 104000000,
                        "bx": 11,
                        "by": 188000000
                    },
                    {
                        "ax": 12,
                        "ay": 117000000,
                        "bx": 12,
                        "by": 190000000
                    }
                ];

                // Create axes
                let valueAxisX = chart.xAxes.push(new am4charts.ValueAxis());
                valueAxisX.title.text = 'Month of Year';
                valueAxisX.renderer.minGridDistance = 40;

                // Create value axis
                let valueAxisY = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxisY.title.text = 'Expense (in Rupiah)';

                // Create series
                let lineSeries = chart.series.push(new am4charts.LineSeries());
                lineSeries.dataFields.valueY = "ay";
                lineSeries.dataFields.valueX = "ax";
                lineSeries.strokeOpacity = 0;

                let lineSeries2 = chart.series.push(new am4charts.LineSeries());
                lineSeries2.dataFields.valueY = "by";
                lineSeries2.dataFields.valueX = "bx";
                lineSeries2.strokeOpacity = 0;

                // Add a bullet
                let bullet = lineSeries.bullets.push(new am4charts.Bullet());

                // Add a triangle to act as am arrow
                let arrow = bullet.createChild(am4core.Triangle);
                arrow.horizontalCenter = "middle";
                arrow.verticalCenter = "middle";
                arrow.strokeWidth = 0;
                arrow.fill = chart.colors.getIndex(0);
                arrow.direction = "top";
                arrow.width = 12;
                arrow.height = 12;

                // Add a bullet
                let bullet2 = lineSeries2.bullets.push(new am4charts.Bullet());

                // Add a triangle to act as am arrow
                let arrow2 = bullet2.createChild(am4core.Triangle);
                arrow2.horizontalCenter = "middle";
                arrow2.verticalCenter = "middle";
                arrow2.rotation = 180;
                arrow2.strokeWidth = 0;
                arrow2.fill = chart.colors.getIndex(3);
                arrow2.direction = "top";
                arrow2.width = 12;
                arrow2.height = 12;

                //add the trendlines
                let trend = chart.series.push(new am4charts.LineSeries());
                trend.dataFields.valueY = "value2";
                trend.dataFields.valueX = "value";
                trend.strokeWidth = 2
                trend.stroke = chart.colors.getIndex(0);
                trend.strokeOpacity = 0.7;
                trend.data = [
                    { "value": 1, "value2": 2 },
                    { "value": 12, "value2": 11 }
                ];

                let trend2 = chart.series.push(new am4charts.LineSeries());
                trend2.dataFields.valueY = "value2";
                trend2.dataFields.valueX = "value";
                trend2.strokeWidth = 2
                trend2.stroke = chart.colors.getIndex(3);
                trend2.strokeOpacity = 0.7;
                trend2.data = [
                    { "value": 1, "value2": 1 },
                    { "value": 12, "value2": 19 }
                ];

                //scrollbars
                chart.scrollbarX = new am4core.Scrollbar();
                chart.scrollbarY = new am4core.Scrollbar();

            }
        }
    }
</script>