<template>
    <div class="card">
        <svg width="114" height="18" viewBox="0 0 114 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-1-4">
            <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B"></path>
            <path d="M32.923 0L35.7991 5.82662L42.2307 6.76111L37.5769 11.2977L38.6752 17.7032L32.923 14.6801L27.1709 17.7032L28.2692 11.2977L23.6154 6.76111L30.047 5.82662L32.923 0Z" fill="#F59E0B"></path>
            <path d="M56.5384 0L59.4145 5.82662L65.8461 6.76111L61.1923 11.2977L62.2906 17.7032L56.5384 14.6801L50.7863 17.7032L51.8846 11.2977L47.2307 6.76111L53.6623 5.82662L56.5384 0Z" fill="#F59E0B"></path>
            <path d="M80.1538 0L83.0298 5.82662L89.4615 6.76111L84.8076 11.2977L85.9059 17.7032L80.1538 14.6801L74.4016 17.7032L75.4999 11.2977L70.8461 6.76111L77.2777 5.82662L80.1538 0Z" fill="#F59E0B"></path>
            <path d="M103.769 0L106.645 5.82662L113.077 6.76111L108.423 11.2977L109.521 17.7032L103.769 14.6801L98.017 17.7032L99.1153 11.2977L94.4614 6.76111L100.893 5.82662L103.769 0Z" fill="#F59E0B"></path>
        </svg>

        <h5>{{ title }}</h5>
        <p class="description">{{  description  }}</p>
        <p class="name">{{ name }}</p>
    </div>
</template>

<script>
    export default {
        props : [
            "title",
            "description",
            "name"
        ]
    }
</script>

<style scoped>
    .card {
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        margin: 20px 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    h5 {
        font-size: 1.5em;
        font-weight: bold;
        color: black;
    }

    p.description {
        font-size: 1.1em;
        font-weight: 300;
        color: #3f3f3f;
        text-align: justify;
    }

    p.name {
        font-size: 1.1em;
        font-weight: bold;
        color: #3f3f3f;
    }
</style>