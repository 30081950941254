<template>
    <div ref="productRecommendationsEngine" style="min-height: 500px;"></div>
</template>

<script>
    /* Imports */
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    export default {
        data () {
            return {

            }
        },
        mounted () {
            this.createChart();
        },
        methods : {
            createChart () {
                /* Chart code */
                // Themes begin
                am4core.useTheme(am4themes_animated);
                // Themes end

                // Create chart instance
                let chart = am4core.create(this.$refs.productRecommendationsEngine, am4charts.XYChart3D);
                chart.paddingBottom = 30;
                chart.angle = 35;

                // Add data
                chart.data = [
                    {
                        "product": "Champagne",
                        "likeliness": "91%"
                    },
                    {
                        "product": "Chocolate Soufflé",
                        "likeliness": "89%"
                    },
                    {
                        "product": "Pan-Seared Scallops",
                        "likeliness": "88%"
                    },
                    {
                        "product": "Creme Brûlée",
                        "likeliness": "86%"
                    },
                    {
                        "product": "Filet Mignon",
                        "likeliness": "85%"
                    },
                    {
                        "product": "Duck Confit",
                        "likeliness": "82%"
                    },
                    {
                        "product": "Wagyu Beef Sushi",
                        "likeliness": "81%"
                    },
                    {
                        "product": "Lobster Bisque",
                        "likeliness": "80%"
                    },
                    {
                        "product": "Truffle Risotto",
                        "likeliness": "79%"
                    },
                    {
                        "product": "Caesar Salad",
                        "likeliness": "75%"
                    }
                ];

                // Create axes
                let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "product";
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.renderer.minGridDistance = 20;
                categoryAxis.renderer.inside = true;
                categoryAxis.renderer.grid.template.disabled = true;

                let labelTemplate = categoryAxis.renderer.labels.template;
                labelTemplate.rotation = -90;
                labelTemplate.horizontalCenter = "left";
                labelTemplate.verticalCenter = "middle";
                labelTemplate.dy = 10; // moves it a bit down;
                labelTemplate.inside = false; // this is done to avoid settings which are not suitable when label is rotated

                let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.renderer.grid.template.disabled = true;
                valueAxis.min = 0;
                valueAxis.max = 100;

                // Create series
                let series = chart.series.push(new am4charts.ConeSeries());
                series.tooltipText = "[bold]{name}[/]\n{categoryX}: [b]{valueY}% likelihood to be purchased[/]";
                series.dataFields.valueY = "likeliness";
                series.dataFields.categoryX = "product";

                let columnTemplate = series.columns.template;
                columnTemplate.adapter.add("fill", function(fill, target) {
                return chart.colors.getIndex(target.dataItem.index);
                })

                columnTemplate.adapter.add("stroke", function(stroke, target) {
                return chart.colors.getIndex(target.dataItem.index);
                })


                // chart.legend = new am4charts.Legend();
                chart.cursor = new am4charts.XYCursor();
            },  
        }
    }
</script>