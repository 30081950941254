<script>
import Landing from '../pages/Landing.vue'
import Dashboard from '../pages/Dashboard.vue'

import PosCounterCheckout from '../pages/Pos-counter-checkout.vue'
import PosCustomerOrder from '../pages/Pos-customer-order.vue'
import PosKitchenOrder from '../pages/Pos-kitchen-order.vue'
import PosMenuStock from '../pages/Pos-menu-stock.vue'
import PosTableBooking from '../pages/Pos-table-booking.vue'

const routes = [
	{ path: '*', redirect: '/' },
  { path: '/', component: Landing },
  { path: '/dashboard', component: Dashboard },
  { path: '/pos/counter-checkout', component: PosCounterCheckout },
  { path: '/pos/customer-order', component: PosCustomerOrder },
  { path: '/pos/kitchen-order', component: PosKitchenOrder },
  { path: '/pos/menu-stock', component: PosMenuStock },
  { path: '/pos/table-booking', component: PosTableBooking },
]

export default routes;
</script>
