import moment from 'moment'
import XLSX from 'xlsx'

var methods={

  errorPrint(errorMessage){//PRINTS ERROR IN RED COLOR
    var error = "%c" + errorMessage;
    console.log(error, 'color:#ff0000')
  },
  printTest(){//PRINTS THE WORD 'TEST'
    console.log("test");
  },
  printDate(date){
    if(date != null) return moment(date).format("DD MMM YY HH:mm")
    else return '-';
  },
  isMobile(){
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      return true;
    } else {
      return false;
    }
  },
  numberWithCommas(x) {
    return parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  exportExcel(data, title) {
    let wb = XLSX.utils.book_new()
    let exportFileName = title ? `${title}.xlsx` : 'Exported Data.xlsx'
    var ws = XLSX.utils.json_to_sheet(data, {raw:true});
    XLSX.utils.book_append_sheet(wb, ws, 'Data')
    XLSX.writeFile(wb, exportFileName)
  },
  randomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
  },
  toCapitalizedWords(name){
    var words = name.match(/[A-Za-z][a-z]*/g) || [];
    return words.map(this.capitalize).join(" ");
  },
  capitalize(s){
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  },
  randomDate(start, end) {//example "2020-02-01 02:30:50" OR just "2020-02-01"
    var startDate = new Date(start)
    var endDate = new Date(end)

    var date = new Date(+startDate + Math.random() * (endDate - startDate));
    return date.toISOString().slice(0, 19).replace(/T/g, " ");
  },
  isRTL () {
    return document.documentElement.getAttribute('dir') === 'rtl' || document.body.getAttribute('dir') === 'rtl'
  },
  featureUnavailable(){
    this.$store.dispatch('triggerAlert', [false, ['Fitur Belum Tersedia']])
  },
  isEmpty(value){
    return (value == null || value === '');
  },
  showLoading (title, subTitle) {
    this.$swal.fire({
        title: title ? title : 'Loading...',
        html : subTitle ? subTitle : null,
        allowOutsideClick: false,
        onBeforeOpen: () => {
          this.$swal.showLoading()
        },
    });
  },
  closeLoading () {
    this.$swal.close();
  },
  triggerSuccess(message){
    this.$swal.fire({
      title: "Success!",
      icon: 'success',
      text: message ? message : "What Message?"
    })
  },
  triggerError(message){
    this.$swal.fire({
      title: "Error!",
      icon: 'error',
      html: message ? message : "What Message?"
    })
  },
  async triggerDeleteConfirmation (message) {
    let confirmResult = false;
    await this.$swal.fire({
      title: 'Are you sure?',
      text: message ? message : "Are you sure you want to delete?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#D33',
      confirmButtonText: 'I am sure'
    }).then((result) => {
      if (result.value) confirmResult = true;
    })
    return confirmResult;
  },


  

  //Load Filter Options
  async loadFilterOptions (module) {
    // var filterList = ['sparepartAttachments','sparepartSizes','brand', 'type', 'model', 'color', 'laborer', 'laborerType', 'supplier', 'customer', 'sparepartVariant', 'woSteps', 'month', 'year', 'insuranceType']
    // var urlList = ['sparepart/attachments','sparepart/sizes', 'car/brand', 'car/type', 'car/model', 'car/color', 'labourer', 'labourer/type', 'supplier', 'customer', 'sparepart/variants', 'steps', 'month', 'year', 'insurance_type']
    var filterList = []
    var urlList = []

    let monthName = ['January' , 'February' , 'March' , 'April', 'May', 'June' , 'July', 'August', 'September', 'October' , 'November', 'December']
    let exceptions = ['sparepart', 'laborerType', 'woSteps']//without sort by name and sort order descending
    var year = []

    if(module != null){
      let index = filterList.indexOf(module)
      if(index != -1) {
        filterList = filterList.splice(index, 1);
        urlList = urlList.splice(index, 1);
      }
    } else {
      //GET COMPANY PROFILE
      // this.getCompanyProfile();
    }

    for (var i = 0 ; i < filterList.length; i++) {
      //if filter exist, skip
      if(this.$store.state.filterOptions[filterList[i]] != null && module == null) continue;

      if (filterList[i] == 'month') {//month
        this.$store.dispatch('setMonth', monthName.map((mon, i) => ({value:i+1, name:mon})))
      } else if (filterList[i] == 'year') {//year
        for (var j = new Date().getFullYear(); j >= 2000; j--) year.push(j.toString())
        this.$store.dispatch('setYear', year)
      } else {//rest of filter
        var url = `/api/v1/masterData/${urlList[i]}?pagination=false`

        if (exceptions.indexOf(filterList[i]) === -1) //Exception
          url+=`&by=name&order=ASC`

        let filter = filterList[i]

        this.$http.get(url)
        .then((resp) => {
          var data = resp.data.data;

          if (filterList[i] == 'customer')
            for(var j in data) data[j].view = `${data[j].phone_number} - ${data[j].name}`

          this.$store.dispatch(`set${this.capitalize(filter)}`, data)
        })
        .catch(err => console.log(err))
      }
    }//FOR LOOP ENDS
  },
  getCompanyProfile(){
    this.$http.get('/api/v1/company_profile')
    .then((resp)=>{
      this.$store.dispatch(`setCompanyProfile`, resp.data.data)
    })
    .catch((err)=>{console.log(err);})
  },
  async getWorkOrderCode(){
    let data;
    await this.$http.get('/api/v1/masterData/workorder?pagination=false&order=DESC&by=created_at')
    .then((resp)=>{
      data = resp.data.data.map(x=>x.form_code)
    })
    .catch((err)=>{console.log(err)})
    return data
  },
  async getInsuranceCompany(){
    let data;
    await this.$http.get('/api/v1/insurance/company?pagination=false&order=ASC&by=name')
    .then((resp)=>{
      data = resp.data.data
    })
    .catch((err)=>{console.log(err)})
    return data;
  },
  async getModules () {
    let result = null
    await this.$http.get('/api/v1/masterData/sidebar')
    .then((resp)=>{
      result = resp.data.data.map((x)=>{
        let firstChild = {path:x.link, title:x.name, icon:x.icon, code:x.code}

        if(x.children.length > 0){
          firstChild.children = x.children.map((y)=>{
            let secondChild = {path:y.link, title:y.name, code:y.code}
            if (y.children.length > 0) secondChild.children=y.children.map((z)=>{return{path:z.link, title:z.name, code:z.code}})
            return secondChild
          })
        }

        return firstChild
      })

      this.$store.state.modules = result
    })
    .catch((err)=>{console.log(err)})
    return result;
  },
}

export default {methods: methods};