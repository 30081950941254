<template>
    <div id="testimonials">

        <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: flex-end;">
            <h3>Real People, Real<br>Results with PIA</h3>
            <a style="color: #4f46e5; font-size: 1.2em;">See all our reviews</a>
        </div>

        <div style="padding: 75px 0">
            <div class="row">
                <div class="col-lg-3 col-6" style="padding: 0 30px">
                    <card
                        title='"A Game-Changer for Us."'
                        description="If you haven't explored PIA yet, it's indispensable for managing an MSME. The inventory capabilities alone are invaluable."
                        name="Mike Harrison - Store Owner"
                    ></card>
                </div>
                <div class="col-lg-3 col-6" style="padding: 0 30px">
                    <card
                        title='"Our Operations Got a Boost."'
                        description="PIA is the skeleton key to unlocking smooth operations in our stores. It's a part of our business DNA now."
                        name="Sara Nguyen - Operations Manager"
                    ></card>
                </div>
                <div class="col-lg-3 col-6" style="padding: 0 30px">
                    <card
                        title='"Data-Driven Success."'
                        description="If you're still contemplating PIA, just dive in. Their AI analytics will revolutionize how you understand your customer base."
                        name="Ahmed El-Sayed - Business Analyst"
                    ></card>
                </div>
                <div class="col-lg-3 col-6" style="padding: 0 30px">
                    <card
                        title='"Scaling Made Easy."'
                        description="PIA not just met but surpassed our expectations. The Pay-As-You-Grow model is perfect for businesses at any stage."
                        name="Emily Patel - CFO"
                    ></card>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import card from './Testimonials/card.vue'

    export default {
        data () {
            return {

            }
        },
        components : {
            card
        },
        mounted () {

        },
        methods : {

        }
    }
</script>

<style scoped>
    #testimonials {
        padding: 100px 0;
    }

    h3 {
        font-size: 3.2em;
        font-weight: bold;
    }
</style>