<template>
    <div id="statistics">

        <div class="row">
            <div class="col-sm-6 col-lg-5 mb-16">
                <div class="mw-sm">
                    <h4>PIA: Revolutionizing MSME Business Operations</h4>
                </div>
            </div>
            <div class="col-sm-6 col-lg-7">
                <div class="row">
                    <div class="col-12 col-lg-4 mb-12 mb-lg-0">
                        <div class="mx-auto" style="max-width: 224px;">
                            <h3 class="mb-3">10k+</h3>
                            <p class="fs-8 text-secondary mb-0">PIA proudly serves over 10,000 satisfied customers.</p>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 mb-12 mb-lg-0">
                        <div class="mx-auto" style="max-width: 224px;">
                            <h3 class="mb-3">1.2M</h3>
                            <p class="fs-8 text-secondary mb-0">Engage with our bustling community of
                                1.2M+ followers on Instagram.</p>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="mx-auto" style="max-width: 224px;">
                            <h3 class="mb-3">98%</h3>
                            <p class="fs-8 text-secondary mb-0">With a 98% customer satisfaction rate,
                                PIA is a name you can trust.</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style scoped>
    #statistics {
        padding: 100px 0;
        background: url(~@/assets/img/gradient.svg) no-repeat center center;
    }

    #statistics h4 {
        font-size: 3.2em;
        font-weight: bold;
    }

    #statistics h3 {
        font-size: 4.5em;
        font-weight: bold;
    }

    #statistics p.text-secondary {
        font-size: 1.4em;
        font-weight: 300;
    }
</style>